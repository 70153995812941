import { useToast } from '@synoptic/ui-kit/toast/toast-provider.js';
import { useCallbackRef } from '@synoptic/ui-kit/utils/use-callback-ref.js';
import { UploadDataWithPathOutput, isCancelError } from 'aws-amplify/storage';
import { nanoid } from 'nanoid';
import { useEffect, useReducer, useRef } from 'react';
import { uploadMedia } from './upload-media';
import { traceError } from '@/utils/trace-error';

const bytesToMb = (bytes: number) => bytes / 1000 / 1000;

const SIZE_LIMIT_MB = 10;

const isOverLimit = (file: File) => bytesToMb(file.size) > SIZE_LIMIT_MB;

export type UploadItem = {
  key: string;
  state: 'loading' | 'success' | 'error';
  progress?: number;
  file: File;
  task: UploadDataWithPathOutput;
};
type UploadsState = {
  [key: string]: UploadItem;
};

type UploadsActions =
  | { type: 'set'; payload: UploadItem[] }
  | { type: 'add'; payload: UploadItem[] }
  | { type: 'remove'; payload: { key: string } }
  | { type: 'progress'; payload: { key: string; progress: number } }
  | { type: 'success'; payload: { key: string } }
  | { type: 'error'; payload: { key: string } }
  | { type: 'reset' };

const uploadsReducer = (
  state: UploadsState,
  action: UploadsActions,
): UploadsState => {
  switch (action.type) {
    case 'add': {
      const newState: UploadsState = {};
      for (const upload of action.payload) {
        newState[upload.key] = upload;
      }

      return {
        ...state,
        ...newState,
      };
    }
    case 'set': {
      const newState: UploadsState = {};
      for (const upload of action.payload) {
        newState[upload.key] = upload;
      }

      return newState;
    }
    case 'remove': {
      const { [action.payload.key]: _removed, ...newState } = state;

      return newState;
    }
    case 'progress': {
      const { key, progress } = action.payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          progress,
        },
      };
    }
    case 'success': {
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          state: 'success',
        },
      };
    }
    case 'error': {
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          state: 'error',
        },
      };
    }
    case 'reset': {
      return {};
    }
    default:
      return state;
  }
};

export type UploadsOpts = { limit: number; mode?: 'append' | 'update' };
const defaultOpts: UploadsOpts = { limit: 4 };

export const useUploads = (
  getPath: (key: string) => string,
  { limit, mode = 'append' }: UploadsOpts = defaultOpts,
) => {
  const toast = useToast();
  const [uploadsByKey, dispatch] = useReducer(uploadsReducer, {});

  const unmounted = useRef(false);

  const dispatchIfMounted: typeof dispatch = (args) =>
    !unmounted.current && dispatch(args);

  const cancelAll = () => {
    Promise.all(
      Object.values(uploadsByKey).map(({ task }) => task.cancel()),
    ).catch(() => {
      // ignore
    });
  };

  const onUnmount = useCallbackRef(() => {
    unmounted.current = true;
    cancelAll();
  });

  useEffect(() => {
    unmounted.current = false;

    return onUnmount;
  }, [onUnmount]);

  const uploadFileList = (fileList: FileList) => {
    const newSize =
      mode === 'append'
        ? Object.keys(uploadsByKey).length + fileList.length
        : fileList.length;

    if (newSize > limit) {
      toast.error({ title: `Please choose up to ${limit} photos` });
      return;
    }

    const filesOverLimit = [];

    const results = [];

    for (const file of fileList) {
      if (isOverLimit(file)) {
        filesOverLimit.push(file.name);
        continue;
      }

      if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
        const key = `${nanoid()}-${file.name}`;

        const task = uploadMedia({
          path: getPath(key),
          file,
          onProgress: ({ transferredBytes, totalBytes }) =>
            totalBytes &&
            dispatchIfMounted({
              type: 'progress',
              payload: {
                key,
                progress: Math.round((transferredBytes / totalBytes) * 100),
              },
            }),
        });

        const payload: UploadItem = {
          key,
          file,
          task,
          state: 'loading',
        };

        results.push(payload);

        task.result
          .then(() => {
            dispatchIfMounted({
              type: 'success',
              payload: {
                key,
              },
            });
          })
          .catch((err) => {
            if (!unmounted.current && !isCancelError(err)) {
              traceError(new Error('Failed to upload media', { cause: err }));
              dispatchIfMounted({
                type: 'error',
                payload: {
                  key,
                },
              });
            }
          });
      }
    }

    dispatch({
      type: mode === 'append' ? 'add' : 'set',
      payload: results,
    });

    if (filesOverLimit.length > 0) {
      toast.error({
        title: `Files ${filesOverLimit.join(', ')} exceed the size limit`,
      });
    }

    return results;
  };

  const onRemove = (key: string) => {
    uploadsByKey[key].task.cancel();
    dispatch({ type: 'remove', payload: { key } });
  };

  const reset = () => {
    dispatch({ type: 'reset' });
    cancelAll();
  };

  const uploads = Object.values(uploadsByKey);

  return { uploadFileList, uploads, onRemove, reset };
};
