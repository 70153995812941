import { S3ObjectInput } from '@/__generated__/graphql';
import { UploadItem } from './use-uploads';
import { AWS_REGION, AWS_S3_BUCKET_NAME } from '@/env';
import { traceError } from '@/utils/trace-error';

async function getFileDimensions(file: File) {
  const src = URL.createObjectURL(file);

  let dimensions;

  if (file.type.startsWith('image/')) {
    const img = new Image();
    img.src = src;
    await img.decode();
    dimensions = { width: img.width, height: img.height };
  } else if (file.type.startsWith('video/')) {
    const video = document.createElement('video');
    video.src = src;

    const { promise, resolve, reject } = Promise.withResolvers();

    video.onloadedmetadata = resolve;
    video.onerror = reject;

    await promise;

    dimensions = { width: video.videoWidth, height: video.videoHeight };
  } else {
    const err = new Error(
      `Can't get file dimensions: Unsupported file type: ${file.type}`,
    );
    traceError(err);
    throw err;
  }

  URL.revokeObjectURL(src);

  return dimensions;
}

export const waitForUploads = (uploads: UploadItem[]) =>
  Promise.all(
    uploads.map<Promise<S3ObjectInput>>(async ({ task, file, key }) => {
      const [, { width, height }] = await Promise.all([
        task.result,
        getFileDimensions(file),
      ]);

      return {
        key: key,
        bucket: AWS_S3_BUCKET_NAME,
        region: AWS_REGION,
        type: file.type,
        width,
        height,
      };
    }),
  );
