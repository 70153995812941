import { TransferProgressEvent, uploadData } from 'aws-amplify/storage';

type UploadMediaProps = {
  path: string;
  file: File;
  onProgress?: (event: TransferProgressEvent) => void;
};
export type UploadMediaPropsWithoutPath = Omit<UploadMediaProps, 'path'>;

export const uploadMedia = ({ path, file, onProgress }: UploadMediaProps) =>
  uploadData({
    path,
    data: file,
    options: {
      contentType: file.type,
      onProgress,
    },
  });
